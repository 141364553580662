import React, { Component } from 'react'
import '../../styles/components/ConsultantItemFiche.css'
import StarsRating from '../../containers/profileComponent/components/StarsRating';
import { routePath } from '../../helpers/route_helper';
import {Link} from 'react-router-dom'
import MonthAvailability from '../MonthAvailability';
import {hasCap} from "../../helpers/app";

export default class ConsultantItemFiche extends Component {
  isEvaluatedByOwner (ratings){
    return ratings && ratings.length === 1 && ratings[0].addedByData.id === this.props.consultant.id;
  }

  getEvaluation(ratings) {
    let average = 0;
    if( ratings ) {
      if ( !this.isEvaluatedByOwner(ratings) ) {
        const total = ratings.filter(item => item.addedByData.id !== this.props.consultant.id).reduce((acc, item) => {
          acc += item.rating;
          return acc;
        }, 0)
        average = total / (ratings.filter(item => item.addedByData.id !== this.props.consultant.id)).length;
      } else {
        average = ratings[0].rating;
      }
    }

    return average;
  }

  render() {
    return (
      <div className="col-lg-4">
        <Link style={{pointerEvents: this.props.isLink ? 'initial' : 'none'}} target="_blank" rel="noopener noreferrer"
              className="card-expert" to={routePath(`consultant/view/${this.props.consultant.id}`)}>
          <div className="card-expert__header text-center text-md-left">
            <div className="avatar float-md-left">
              {this.props.consultant.profilePhoto ?
                <img src={this.props.consultant.profilePhoto.contentUrl} alt=""/> : null}
            </div>
            <div className="info">
              <h2 className="title">{this.props.consultant.fullName}</h2>
              {this.props.consultant.role.name !== 'EXTERNE' &&
                <>
                  <h3
                    className="sub-title">{(this.props.consultant.position !== null) ? this.props.consultant.position.name : null}</h3>
                  <div className="desc"><i
                    className="far fa-building"/>{(this.props.consultant.workplace) ? this.props.consultant.workplace.name : null}
                  </div>
                </>
              }
            </div>
            <div className="clear"/>
          </div>

          {(hasCap('PARTNER', this.props.role) || this.props.referePage !== undefined)
            && !this.props.consultant.externe
            && !(this.props.consultant.role.name === 'RH' && this.props.role === 'RH')
            &&
            <Link
              target="_blank" rel="noreferrer noopener"
              style={{color: '#003D71', fontSize: 14, marginBottom: 8, display: 'block'}}
              to={`/development/feedbacks?user=${this.props.consultant.id}`}
            >
              Voir le plan de développement
            </Link>
          }
          {this.props.consultant.role.name !== 'EXTERNE' &&
            <MonthAvailability availabilities={this.props.consultant.availabilitiesData}/>}

          {
            this.props.consultant.role.name === 'EXTERNE' &&
            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 15, alignItems: 'center'}}>
              <div>
                {!this.props.consultant.actif ?
                  <span style={{fontSize: 14, fontWeight: 'bold'}}> Inactif <span className="inactif"/></span> :
                  <span style={{fontSize: 14, fontWeight: 'bold'}}> Actif <span className="actif"/></span>}
              </div>
              <span className="tag">Externe</span>
            </div>
          }

          <div className="card-expert__body">
            <h4 className="card-title">Expertise / Compétences</h4>
            <ul className="list-skills">
              {
                this.props.consultant.userSkills.map((item, i) =>
                  <li key={i} className="list-skills__item text-item">
                    <h6>{item.experience.name}</h6>
                    <div className="stars">
                      <StarsRating
                        stars={4}
                        rating={this.getEvaluation(item.ratings)}
                        isEvaluatedByOwner={this.isEvaluatedByOwner(item.ratings)}
                      />
                    </div>
                  </li>
                )
              }
            </ul>
          </div>
          <div className="card-expert__footer text-center">
            Voir plus
          </div>
        </Link>
      </div>
    )
  }
}
